import { paginatedRequest } from '@/services/api/index'

const EmailhandlerAPI = {
  handler_configuration: {
    list: async () => {
      const response = await paginatedRequest(
        '/messaging/handlerconfiguration'
      )
      return response.data
    }
  }
}
export default EmailhandlerAPI
