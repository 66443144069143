<template>
  <div v-if="prop">
    <div class="title">
      <el-row
        type="flex"
        justify="start"
      >
        <el-col
          class="back-button"
          :span="2"
        >
          <i
            class="el-icon-back"
            @click="handleBackButton"
          />
        </el-col>
        <el-col>
          <h3>
            {{ prop.property.name }}
          </h3>
        </el-col>
      </el-row>
    </div>
    <div
      class="handler-form"
    >
      <el-row
        type="flex"
        justify="space-between"
        class="form-header-row"
      >
        <el-col :span="12" class="form-header-title">
          <h1>
            Email Handler Configuration
          </h1>
        </el-col>
        <el-col :span="3.6" class="form-header-buttons">
          <el-button class="form-header-button" @click="navigateToEmailLog">
            View Email Log
            <i class="el-icon-news" />
          </el-button>
        </el-col>
      </el-row>
      <div v-if="!emailHandlerEnabled()" class="alert">
        <el-alert
          type="warning"
          title="Warning: This property does not have an active &quot;lead tracking (email)&quot; feature subscription.
                 Emails to properties without email lead tracking will be dropped."
          show-icon
          :closable="false"
        />
      </div>
      <emailhandler-form
        :property="prop"
        @modified="modified = $event"
        @submit="prop = $event"
      />
    </div>
  </div>
</template>

<script>
import { enums } from '@/utils/constants'
import { getFeatureSet } from '@/utils/subscriptions'
import EmailHandlerForm from '@/views/posting-groups/properties/emailhandler-configuration/_components/EmailHandlerForm'
import CraigslistAPI from '@/services/api/craigslist'

export default {
  name: 'PropertyEmailhandlerConfiguration',
  components: {
    'emailhandler-form': EmailHandlerForm
  },
  data () {
    return {
      prop: null,
      modified: false,
      loading: false
    }
  },
  created () {
    this.getProperty()
  },
  beforeRouteUpdate (to, from, next) {
    if (to.params.id !== from.params.id) {
      this.getProperty()
    }
    next()
  },
  beforeRouteLeave (to, from, next) {
    if (this.modified) {
      const answer = window.confirm('Form data has been modified without saving. Continue?')
      if (!answer) {
        next(false)
        return
      }
    }
    this.$store.commit('CLEAR', 'selectedProperty')
    next()
  },
  methods: {
    /**
     * Fetches property from API
     */
    async getProperty () {
      try {
        const response = await CraigslistAPI.properties.retrieve(this.$route.params.id)
        this.prop = response
        this.$store.commit('SET', ['selectedProperty', response])
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
    },
    /**
     * Determines if this property has the 'Lead Tracking (email)' feature subscription, we are only going
     * to drop emails for props with a status of 'inactive', 'pending', or 'invalid'
     */
    emailHandlerEnabled () {
      const featureSet = getFeatureSet(this.prop.property, status => ![enums.status.INACTIVE, enums.status.PENDING, enums.status.INVALID].includes(status))
      return featureSet.has(enums.features.EMAIL)
    },
    /**
     * Takes the user back to the property edit form
     */
    handleBackButton () {
      this.$router.push({ name: 'PropertyEdit', params: { property: this.prop } })
    },
    /**
     * Takes the user to the logs in Django Admin
     */
    navigateToEmailLog () {
      const query = this.prop.property.name.replaceAll(' ', '+')
      const urlBase = `${process.env.VUE_APP_API_ADMIN_URL}emailhandler/mailgunmessage/?q=`
      window.open(urlBase + query)
    }
  }
}
</script>

<style lang="scss" scoped>
.handler-form {
  border-style: solid;
  border-color: #EDF0F3;
  padding: 10px;
  width: 800px;
}
.back-button {
  margin: auto 10px auto 0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}
.title {
  padding-bottom: 20px;
  max-width: 800px;
  min-width: 487px;
}
.el-icon-back {
  font-size: 2em;
}
.form-header-row{
  padding-bottom: 10px;
}
.form-header-title{
  margin-top: -10px;
}
.form-header-buttons {
  justify-content: flex-end;
}
.form-header-button {
  color: #1473E6;
}
.alert {
  padding-bottom: 20px;
  font-style: italic;
}
</style>
