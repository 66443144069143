<template>
  <div v-if="property">
    <el-form
      ref="emailhandler-form"
      :model="formData"
      :rules="rules"
      label-position="right"
      label-width="200px"
      size="medium"
    >
      <el-alert
        v-for="(msg, index) in alerts"
        :key="index"
        :type="msg.type"
        :title="msg.message"
        show-icon
        class="alert"
        :closable="false"
      />
      <el-form-item
        label="Email Delivery Lead"
        prop="property.email_delivery_lead"
      >
        <el-input
          v-model="formData.property.email_delivery_lead"
          class="form-input"
          :disabled="isCRMIntegration"
        />
        <el-popover
          placement="right"
          content="Lead emails are delivered to this address"
          trigger="hover"
        >
          <i slot="reference" class="el-icon-info" />
        </el-popover>
      </el-form-item>

      <el-form-item
        label="Email Delivery Lead Copy"
        prop="property.email_delivery_lead_copy"
      >
        <el-input v-model="formData.property.email_delivery_lead_copy" class="form-input" />
        <el-popover
          placement="right"
          content="Lead emails are delivered to this address as well as to the &quot;Email Delivery Lead&quot;"
          trigger="hover"
        >
          <i slot="reference" class="el-icon-info" />
        </el-popover>
      </el-form-item>

      <el-alert
        v-if="hasPostingService"
        type="info"
        show-icon
        class="posting-service-alert"
        :closable="false"
      >
        <template #title>
          If this property is subscribed to Full Service the Email Delivery Property field is automatically set to caasnonactionable@postengine.com and is not editable.
        </template>
      </el-alert>

      <el-form-item v-if="hasPostingService" label="Email Delivery Property">
        <el-input
          value="caasnonactionable@postengine.com"
          disabled
          class="form-input"
        />
      </el-form-item>

      <el-form-item
        v-else
        label="Email Delivery Property"
        prop="property.email_delivery_property"
      >
        <el-input v-model="formData.property.email_delivery_property" class="form-input" />
        <el-popover
          placement="right"
          content="Craigslist automated emails are sent to this address"
          trigger="hover"
        >
          <i slot="reference" class="el-icon-info" />
        </el-popover>
      </el-form-item>

      <el-form-item
        label="Handler Configuration"
        prop="property.handler_configuration"
      >
        <el-row>
          <el-select
            v-model="formData.property.handler_configuration"
            placeholder="Type to search"
            filterable
            class="handler-config-form-input"
          >
            <el-option
              v-for="config in handlerConfigurations"
              :key="config.id"
              :label="config.name"
              :value="config.id"
            />
          </el-select>
          <div class="handler-config-action-buttons">
            <a :href="handlerConfigurationAddPage" target="_blank">
              <i class="el-icon-circle-plus" />
            </a>
            <a
              :href="handlerConfigurationEditPage"
              target="_blank"
              class="edit-icon"
            >
              <edit-icon />
            </a>
          </div>
          <el-popover
            placement="right"
            content="HandlerConfiguration for this property"
            trigger="hover"
          >
            <i slot="reference" class="el-icon-info" />
          </el-popover>
        </el-row>
      </el-form-item>

      <el-form-item
        v-if="company"
        label="Company Lead Source"
      >
        <el-input
          v-model="companyLeadSource"
          class="form-input"
          :disabled="true"
        />
        <el-popover
          placement="right"
          content="CRM leads for this company show as coming from this source. You can edit this value on the Company"
          trigger="hover"
        >
          <i slot="reference" class="el-icon-info" />
        </el-popover>
      </el-form-item>

      <el-form-item
        label="CRM ID"
        prop="property.crm_id"
      >
        <el-input
          v-model="formData.property.crm_id"
          class="form-input"
          :disabled="!isCRMIntegration"
        />
        <el-popover
          placement="right"
          content="Only required if the Handler Configuration uses a CRM integration"
          trigger="hover"
        >
          <i slot="reference" class="el-icon-info" />
        </el-popover>
      </el-form-item>

      <el-form-item label="Craigslist Account" prop="craigslist_accounts">
        <craigslist-account-multiselect
          class="form-input cl-account-select"
          :selected="formData.craigslist_accounts"
          :show-copy-buttons="false"
          :show-edit-button="false"
          @input="formData.craigslist_accounts = $event"
          @create-account="handleShowCreateAccountForm($event)"
          @display-message="alerts.push({ type: 'warning', message: $event })"
        />
        <el-popover
          placement="right"
          content="CraigslistAccounts associated with this property. Saving this form will ensure that each account is only associated with this property"
          trigger="hover"
        >
          <i slot="reference" class="el-icon-info" />
        </el-popover>
      </el-form-item>

      <el-button
        :loading="loading"
        type="primary"
        @click="submit()"
      >
        Save
      </el-button>
    </el-form>
    <form-dialog
      :show="showCreateDialog"
      :show-close="true"
      title="Add Craigslist Account"
      width="500px"
      @close="showCreateDialog = false"
    >
      <account-create-form
        :email="createCraigslistAccountEmail"
        @submit="createCraigslistAccount"
        @cancel="showCreateDialog = false"
      />
    </form-dialog>
  </div>
</template>

<script>
import { enums } from '@/utils/constants'
import { getFeatureSet } from '@/utils/subscriptions'
import EmailhandlerAPI from '@/services/api/emailhandler'
import CraigslistAPI from '@/services/api/craigslist'
import CraigslistAccountMultiSelect from '@/views/posting-groups/properties/property-form/_components/forms/property/PropertyFormLeadTracking/CraigslistAccountMultiSelect'
import FormDialog from '@/components/dialogs/FormDialog'
import CraigslistAccountCreateForm from '@/views/posting-groups/properties/property-form/_components/forms/property/PropertyFormLeadTracking/CraigslistAccountCreateForm'
import RooofAPI from '@/services/api/rooof'
import EditIcon from '@/assets/icons/svg/pencil.svg'

export default {
  name: 'EmailHandlerForm',
  components: {
    'craigslist-account-multiselect': CraigslistAccountMultiSelect,
    'form-dialog': FormDialog,
    'edit-icon': EditIcon,
    'account-create-form': CraigslistAccountCreateForm
  },
  props: {
    property: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      alerts: [],
      showCreateDialog: false,
      createCraigslistAccountEmail: undefined,
      company: null,
      handlerConfigurationEditPage: null,
      craigslistAccounts: [],
      handlerConfigurations: [],
      formData: {
        property: {
          features_list: [],
          email_delivery_lead: '',
          email_delivery_lead_copy: '',
          email_delivery_property: '',
          crm_id: '',
          handler_configuration: null
        },
        craigslist_accounts: []
      }
    }
  },
  computed: {
    /**
     * Returns true if property has an active subscription to Posting Service
     *
     */
    hasPostingService () {
      if (!this.formData.property.features_list) {
        return false
      }
      const featureSet = getFeatureSet(this.formData.property, status => [enums.status.ACTIVE, enums.status.OFFBOARDING, enums.status.TRIAL, enums.status.PRE_TRIAL].includes(status))
      return featureSet.has(enums.features.POSTING_SERVICE)
    },
    handlerConfigurationAddPage () {
      return `${process.env.VUE_APP_API_ADMIN_URL}emailhandler/handlerconfiguration/add/`
    },
    companyLeadSource () {
      if (this.company && this.company.emailhandler_lead_source) {
        return this.company.emailhandler_lead_source
      } else {
        return '<default: "Craigslist/Rooof">'
      }
    },
    isCRMIntegration () {
      if (!this.handlerConfigurations) {
        return false
      }
      const hc = this.handlerConfigurations.find(hc => hc.id === this.formData.property.handler_configuration)
      if (!hc) {
        return false
      }
      return !(hc.lead_inbound.includes('mailgun') || hc.lead_inbound === 'email')
    },
    rules () {
      return {
        property: {
          email_delivery_lead: [
            { required: !this.isCRMIntegration, message: 'Enter email to receive leads for this property' },
            { type: 'email', message: 'Please provide a valid email address', trigger: 'blur' }
          ],
          email_delivery_lead_copy: [
            { required: false },
            { type: 'email', message: 'Please provide a valid email address', trigger: 'blur' }
          ],
          crm_id: [
            { required: this.isCRMIntegration, message: 'Please provide a CRM ID' },
            { type: 'string', message: 'Please provide a CRM ID', trigger: 'blur' }
          ],
          email_delivery_property: [
            {
              required: true,
              message: 'Enter email to receive Craigslist emails for this property'
            },
            { type: 'email', message: 'Please provide a valid email address', trigger: 'blur' }],
          handler_configuration: { required: true, message: 'Handler configuration is required' }
        },
        craigslist_accounts: {
          required: true,
          message: 'At least one Craigslist account is required',
          validator: this.validateCraigslistAccounts
        }
      }
    }
  },
  watch: {
    formData: {
      handler (value) {
        let modified = JSON.stringify(value.craigslist_accounts) !== JSON.stringify(this.property.craigslist_accounts)
        for (const field in this.formData.property) {
          modified = modified || value.property[field] !== this.property.property[field]
        }
        this.$emit('modified', modified)
        this.handlerConfigurationEditPage = `${process.env.VUE_APP_API_ADMIN_URL}emailhandler/handlerconfiguration/${this.formData.property.handler_configuration}/change/`
      },
      deep: true
    }
  },
  async created () {
    for (const field in this.formData.property) {
      this.formData.property[field] = this.property.property[field]
    }
    this.handlerConfigurations = await EmailhandlerAPI.handler_configuration.list()
    this.formData.craigslist_accounts = JSON.parse(JSON.stringify(this.property.craigslist_accounts))
    this.company = await RooofAPI.companies.retrieve(this.property.property.company)
  },
  methods: {
    /**
     * Sends form data to Production
     * Displays errors if necessary
     */
    async submit () {
      this.alerts = []
      if (!await this.isValid()) {
        this.alerts.push({ type: 'error', message: 'One or more of the following fields are invalid' })
        return
      }
      const clProperty = {
        property: { ...this.property.property, ...this.formData.property },
        craigslist_accounts: JSON.parse(JSON.stringify(this.formData.craigslist_accounts))
      }
      try {
        await CraigslistAPI.properties.update(this.property.property.id, clProperty)
        await RooofAPI.properties.update(this.property.property.id, clProperty.property)
        await this.updateCLAccounts(clProperty.craigslist_accounts)
        this.alerts.push({ type: 'success', message: 'Successfully updated Email Handler Configuration' })
        this.$emit('modified', false)
        this.$emit('submit', clProperty)
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err, details)
        this.alerts.push({ type: 'error', message: 'Unable to update Email Handler Configuration (API Error)' })
      }
    },
    /**
     * Update Craigslist Accounts
     *
     * Each CL account that is associated with this property must not be associated with any other properties
     * @param clAccounts list of CL account emails
     */
    async updateCLAccounts (clAccounts) {
      for (const clAccount of clAccounts) {
        const updateData = { properties: [this.property.property.id] }
        await CraigslistAPI.accounts.partialUpdate(clAccount, updateData)
      }
    },
    /**
     * Validates the form
     */
    async isValid () {
      return new Promise(resolve => {
        this.$refs['emailhandler-form'].validate((valid) => {
          return resolve(valid)
        })
      })
    },
    /**
     * Ensures there is exactly one CraigslistAccount specified
     *
     * @param rule Object: Rule we are validating for
     * @param value Observer: Value we are validating
     * @param callback function: Function to call after
     */
    validateCraigslistAccounts (rule, value, callback) {
      if (value.length < 1) {
        callback(new Error('At least one CraigslistAccount must be supplied'))
      }
      callback()
    },
    /**
     * Show the create craigslist account form with the email the user searched for
     *
     * @param {String} email: Email to use in the craigslist account form
     */
    handleShowCreateAccountForm (email) {
      this.createCraigslistAccountEmail = email
      this.showCreateDialog = true
    },
    /**
     * Send request to create a new craigslist
     * account to the api.
     *
     * @param {Object} data
     * @param {String} data.email
     * @param {String} data.password
     * @param {(Number|null)} data.region
     */
    async createCraigslistAccount (data) {
      try {
        const payload = {
          ...data,
          status: 'active',
          company: this.company.id,
          properties: [this.property.property.id]
        }
        const response = await CraigslistAPI.accounts.create(payload)
        this.formData.craigslist_accounts.push(response.email)
        this.showCreateDialog = false
        this.alerts.push({ type: 'success', message: `Account ${data.email} created` })
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err, details)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$color-primary: #1a7dc6;
.form-input {
  width: 80%;
  padding-right: 2%;
}

.handler-config-form-input {
  padding-right: 1%;
  width: 70%;
}

.handler-config-action-buttons {
  display: inline;
  padding-right: 2%;
}

.edit-icon {
  vertical-align: middle;
}

i {
  font-size: 1.55em;
  margin: 0 auto;
}

.el-icon-circle-plus {
  font-size: 1.6em;
  color: #67c23a;
  margin: 0 5px;
}

.alert {
  margin: 0 10px 20px 0;
}

.posting-service-alert {
  margin-bottom: 1em;
  background-color: rgba($color-primary, 0.2);
  color: $color-primary;
}

.cl-account-select {
  float: left;
}
</style>
